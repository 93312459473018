import {Component} from '@angular/core';
import {AppContext} from "../app-context";
import {Bunkering} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-bunkering',
  templateUrl: './bunkering.component.html',
  styleUrls: ['./bunkering.scss']
})
export class BunkeringComponent {
  showExemptions(): boolean {
    return AppContext.environment === 'localhost' || AppContext.environment === "kt";
  }
}

export interface LocalBunkering extends Bunkering {
  draft: boolean;
}
