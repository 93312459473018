import {
  GoodsClassification,
  GuaranteeData,
  IE3MasterConsignment,
  NonArrivedInvestigationInfo,
  PreviousDocument,
  SecurityDetails,
  TransitData,
  TransitDeclaration,
  TransitGoodsItem,
  TransitInspectionStatus,
  TransitPhase,
  TransitStatus,
  TransportDetails
} from "@portbase/bezoekschip-service-typescriptmodels";
import {AppContext} from "../app-context";
import {ComparatorChain} from '../common/comparator-chain';
import {VisitContext} from '../visit-details/visit-context';
import {lodash, nanoId, removeItem, sendQuery} from "../common/utils";
import moment from "moment";
import {map} from "rxjs/operators";

const transitPhases: TransitPhase[] = [TransitPhase.undeclared, TransitPhase.inspection, TransitPhase.releaseRequested,
  TransitPhase.insufficientGuarantee, TransitPhase.rejected, TransitPhase.releaseRejected, TransitPhase.declared,
  TransitPhase.sent, TransitPhase.cancellationRequested, TransitPhase.accepted, TransitPhase.inTransit,
  TransitPhase.proofOfArrivalRequested, TransitPhase.proofOfArrivalSent, TransitPhase.arrived,
  TransitPhase.discrepancies, TransitPhase.writtenOff, TransitPhase.cancelled];

function filterOutShortGoodsClassificationCodes() {
  return (goodsClassification: GoodsClassification) => {
    const length = goodsClassification?.code?.length;

    return length === 6 || length === 8;
  };
}

export const findTransitClassifications = (term: string) =>
  sendQuery("com.portbase.bezoekschip.common.api.cargo.FindGoodsClassifications", {term: term})
    .pipe(map((array: GoodsClassification[]) => array.filter(filterOutShortGoodsClassificationCodes())));

export const transitStatusComparator: ComparatorChain = new ComparatorChain(
  (a: TransitDeclaration, b: TransitDeclaration): number => ComparatorChain.compareValues(a.status?.sent?.date || a.status?.sendDate, b.status?.sent?.date || b.status?.sendDate, false, "+"),
  (a: TransitDeclaration, b: TransitDeclaration): number => transitPhases.indexOf(a.status.phase) - transitPhases.indexOf(b.status.phase),
  'data.containerNumber');

export const transitItemComparator: ComparatorChain = transitStatusComparator.reverseProperties();

export function initializeTransit(o: TransitDeclaration = undefined): TransitDeclaration {
  const input = o || <TransitDeclaration>{}
  const status = input.status || <TransitStatus>{};
  const result = input;

  result.declarantShortName = input.declarantShortName || AppContext.userProfile.organisation?.shortName;
  result.lrn = input.lrn || nanoId();
  result.crn = input.crn || (VisitContext.savedVisit ? VisitContext.savedVisit.crn : null);
  result.data = initializeTransitData(input.data)
  result.dataFromCustoms = initializeTransitData(input.dataFromCustoms)
  result.status = status;
  result.status.inspectionStatus = result.status.inspectionStatus || <TransitInspectionStatus>{};
  result.status.info = result.status.info || <NonArrivedInvestigationInfo>{};

  if (result.status.mrnSent && ["APMRTM", "APMII", "BCW", "RWG", "UNIP"]
    .some(t => t === result.data.dischargeTerminal?.organisationShortName)) {
    //we don't get accepts from these terminals (they're still using the legacy MID 1.0)
    result.status.mrnAccept = result.status.mrnSent;
  }

  if (!o && result.crn) {
    result.status.sendDate = null;
    result.status.sendOnAta = true;
  } else if (!status.sendDate || moment(status.sendDate).isBefore(moment())) {
    result.status.sendDate = moment().format('YYYY-MM-DD');
  }

  if (result.data.goodsItems.length === 0 &&
    (!result.data.houseConsignments || result.data.houseConsignments.length === 0)) {
    addEmptyGoodsItem(result);
  }

  if (result.data.guarantees.length === 0) {
    addEmptyGuarantee(result);
  }

  return result;
}

export function initializeTransitData(o: TransitData = <TransitData>{}): TransitData {
  const data = o || <TransitData>{};
  data.transitOffices = data.transitOffices || [];
  data.departureTransport = data.departureTransport || <TransportDetails>{};
  data.borderTransport = data.borderTransport || <TransportDetails>{};
  data.securityDetails = data.securityDetails || <SecurityDetails>{};
  data.securityActivated = data.securityActivated || false;
  data.bindingItinerary = data.bindingItinerary || false;
  data.transportTerm = <any>(typeof data.transportTerm == 'string' ? moment.duration(data.transportTerm).asSeconds() : data.transportTerm);
  data.seals = data.seals || [];
  data.itinerary = data.itinerary || [];
  data.goodsItems = data.goodsItems || [];
  data.guarantees = data.guarantees || [];
  return data;
}

export function fixUpTransitDataBeforeSubmission(input: TransitData): TransitData {
  const borderTransport = input?.borderTransport;
  const hasHouseConsignment = input?.houseConsignments && input?.houseConsignments.length > 0;

  if (borderTransport || hasHouseConsignment) {
    const output = Object.assign({}, input);

    if (hasHouseConsignment) {
      input.houseConsignments.forEach(houseConsignment => {
        houseConsignment.goodsItems.forEach(goodsItem => {
          if (!output.goodsItems.some(item => item === goodsItem)) {
            removeItem(houseConsignment.goodsItems, goodsItem);
          }
        });

        if (houseConsignment.goodsItems.length === 0) {
          removeItem(input.houseConsignments, houseConsignment);
        }
      });

      delete output.goodsItems;
    }

    if (!borderTransport.modeOfTransport && !borderTransport.nationality && !borderTransport.reference) {
      delete output.borderTransport;
    }

    return output;
  } else {
    return input;
  }
}

export function copyHouseConsignmentsGoodsItemsToMasterGoodsItems(data: TransitData): boolean {
  const houseConsignments = data.houseConsignments;

  if (houseConsignments && houseConsignments.length > 0) {
    data.goodsItems = lodash.flatMap(houseConsignments, houseConsignment => houseConsignment.goodsItems);

    return true;
  } else {
    return false;
  }
}

export function formatSendDate(sendDate: string, sendOnAta: boolean): string {
  if (sendOnAta || !sendDate) {
    return null;
  } else {
    return moment(sendDate).format("YYYY-MM-DD") + "T00:00:00Z";
  }
}

export function addEmptyGoodsItem(declaration: TransitDeclaration) {
  declaration.data.goodsItems.splice(0, 0, <TransitGoodsItem>{
    previousDocument: <PreviousDocument>{},
    producedDocuments: []
  });
}

export function addEmptyGuarantee(declaration: TransitDeclaration) {
  declaration.data.guarantees.push(<GuaranteeData>{
    type: null,
    country: {},
    customsValue: {
      currency: {}
    }
  });
}

export function getGoodsItems(masterConsignment: IE3MasterConsignment) {
  if (masterConsignment == null) {
    return null;
  } else {
    const masterConsignmentGoodsItems = masterConsignment.goodsItems;

    if (masterConsignmentGoodsItems != null && masterConsignmentGoodsItems.length > 0) {
      return masterConsignmentGoodsItems;
    } else {
      return lodash.flatMap(masterConsignment.houseConsignments, houseConsignment => houseConsignment.goodsItems);
    }
  }
}
