import {Component, OnDestroy, OnInit} from '@angular/core';
import {EditBunkeringComponent} from "../edit/edit-bunkering.component";
import {formatDateString, nanoId, openEditModal, sendQuery, toWebsocketUrl} from "../../common/utils";
import {AppContext} from "../../app-context";
import {
  Bunkering,
  BunkeringSummary,
  DeclarationInput,
  SearchBunkerings
} from "@portbase/bezoekschip-service-typescriptmodels";
import {EventGateway} from "../../common/event-gateway";
import {BunkeringService} from "../bunkering.service";
import lodash from "lodash";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";

@Component({
  selector: 'app-bunkering-declaration',
  templateUrl: './bunkering-declaration.component.html',
  styleUrls: ['../bunkering.scss', './bunkering-declaration.component.css']
})
export class BunkeringDeclarationComponent implements OnInit, OnDestroy {

  appContext = AppContext;

  socket: WebSocket;

  bunkerings: BunkeringSummary[] = [];

  searchTerm: string;

  constructor(private eventGateway: EventGateway) {
    this.eventGateway.registerLocalHandler(this);
  }

  ngOnInit() {
    this.reload();
    this.subscribeToBunkeringUpdates();
  }

  ngOnDestroy(): void {
    try {
      this.socket.onclose = () => {
      };
      this.socket.close();
      this.socket = null;
    } catch (ignored) {
    }
  }

  addBunkering() {
    openEditModal(EditBunkeringComponent, <LocalBunkering>{
      draft: true,
      sendProToCustoms: true,
      bunkeringId: nanoId(32),
      input: <DeclarationInput>{items: [{}]}
    });
  }

  private reload = () => {
    this.searchFunction("").subscribe(values => this.bunkerings = values);
  };

  searchFunction = (searchTerm: string): Observable<any> => {
    return sendQuery('com.portbase.bezoekschip.common.api.bunkering.SearchBunkerings', <SearchBunkerings>{term: searchTerm})
  };

  renderBunkerings = (summaries: BunkeringSummary[]) => {
    this.bunkerings = summaries;
  }

  editBunkering(summary: BunkeringSummary) {
    BunkeringService.myBunkering(summary.crn, summary.berthVisitId, summary.bunkeringId).subscribe((bunkering: Bunkering) => {
      openEditModal(EditBunkeringComponent, bunkering);
    })
  }

  handleBunkeringUpdate = (b: Bunkering) => {
    if (this.bunkerings) {
      const filteredBunkerings = this.bunkerings.filter(i => i.bunkeringId === b.bunkeringId);

      if (filteredBunkerings.length > 0) {
        filteredBunkerings.forEach(i => lodash.merge(i, b));
      } else {
        this.bunkerings.splice(0, 0, b);
      }
    }
  };

  private subscribeToBunkeringUpdates = () => {
    if (environment.production || environment.docker) {
      try {
        this.socket = new WebSocket(toWebsocketUrl("/api/ui/bunkerings"));
      } catch (e) {
        console.info("Could not open websocket. Retrying every minute...", e);
        setTimeout(this.subscribeToBunkeringUpdates, 60_000);
        return;
      }
      this.socket.onmessage = (message: MessageEvent) => {
        console.log('MESSAGE EVENT', JSON.stringify(JSON.parse(message.data)));
        if (typeof message.data === 'string') {
          this.eventGateway.publish("handleBunkeringUpdate", JSON.parse(message.data));
        }
      };
      this.socket.onclose = (event: CloseEvent) => {
        if (!event.wasClean) {
          console.warn("Websocket closed with reason: " + event.reason + " (" + event.code + "). Trying to reconnect...");
        }
        setTimeout(() => this.subscribeToBunkeringUpdates(), 5_000);
      }
    }
  }

  formatDate = (dateString: string): string => formatDateString(dateString);

}

export interface LocalBunkering extends Bunkering {
  draft: boolean;
}
