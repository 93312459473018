import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {
  DateTimeRange,
  DownloadableFile,
  FindTransitDeclarations,
  InitiateTransitForForwarder,
  Item,
  TransitDeclaration,
  TransitStatus
} from '@portbase/bezoekschip-service-typescriptmodels';
import {AppContext} from '../app-context';
import {cloneObject, lodash, nanoId, removeItem, sendQuery, toWebsocketUrl} from '../common/utils';
import {AbstractOverviewComponent} from '../common/abstract-overview.component';
import {Observable, Subject} from 'rxjs';
import {
  copyHouseConsignmentsGoodsItemsToMasterGoodsItems,
  initializeTransit,
  transitItemComparator,
  transitStatusComparator
} from './transit.utils';
import {concatMap, map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {TransitFilter} from './transit-filter/transit-filter.component';
import moment from 'moment';
import {PortvisitUtils} from '../refdata/portvisit-utils';
import {ActivatedRoute} from '@angular/router';
import {downloadTransitCsv} from './transit-csv';

@Component({
  selector: 'app-transit-overview',
  templateUrl: './transit-overview.component.html',
  styleUrls: ['./transit-overview.component.css']
})
export class TransitOverviewComponent extends AbstractOverviewComponent<TransitDeclaration> implements OnInit, OnDestroy {
  appContext = AppContext;
  refData = PortvisitUtils;
  transitDeclarations: TransitDeclaration[] = [];
  socket: WebSocket;
  transitFilter: TransitFilter = {deselectedOptions: [], selectedOptions: [], checkCondition: null};
  sortBy = 'sendDateDescending';
  showFilter: boolean = true;
  transitDateFilterOptions: string[] = ["SEND", "EXPIRY", "TRANSPORT_TERM"];
  transitDateFilterType: string = "TRANSPORT_TERM";
  preselectedItems: Item[] = [];
  sortingProperties = ['sendDate', 'sendDateDescending', 'containerNumber', 'containerNumberDescending']
  transitDateFilterTypeFormatter = (option) => {
    switch (option) {
      case "SEND":
        return "Send date";
      case "EXPIRY":
        return "Expiry date";
      case "TRANSPORT_TERM":
        return "Transport term";
    }
  };

  private documentsDownloader: Subject<DownloadableFile> = new Subject<DownloadableFile>();

  localStorageKey(): string {
    return "transit-overview";
  }

  constructor(private zone: NgZone, route: ActivatedRoute) {
    super();

    route.queryParams.subscribe(queryParams => this.initializeForIFrame(
      queryParams['crn'],
      queryParams['consignmentNumber'],
      queryParams['containerNumber'],
      queryParams['items']));
  }

  private initializeForIFrame(crn, consignmentNumber, containerNumber, items) {
    if (crn && consignmentNumber) {
      this.preselectedItems = [{
        crn: crn,
        consignmentNumber: consignmentNumber,
        containerNumber: containerNumber
      }];
    } else if (items) {
      const initiateTransit: InitiateTransitForForwarder = JSON.parse(atob(items));

      this.preselectedItems = initiateTransit.items;
    }

    if (this.preselectedItems.length > 0) {
      setTimeout(() => $('#preselectedDeclaration').modal('show'), 0);
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscribeToTransitUpdates();
    this.subscribeToDownloadedFiles();
  }

  createNextTransitDeclaration = (previous: TransitDeclaration) => {
    let next = cloneObject(previous);
    delete next.status;
    delete next.crn;
    delete next.dataFromCustoms;
    delete next.requestedTransportExpiryDate;
    delete next.transportExpiryDate;
    next.lrn = nanoId();
    next.data.goodsItems.forEach(g => g.previousDocument = {
      type: {
        "code": "N821",
        "description": "Aangifte tot plaatsing onder de regeling extern communautair douanevervoer/gemeenschappelijk douanevervoer, T1"
      },
      documentNumber: previous.status.mrn,
      sequenceNumber: 1
    });
    next["isNewTransitDeclaration"] = true;
    next["isContainerDeclaration"] = !!next.data.containerNumber;
    next = initializeTransit(next);
    this.addDeclaration(next);
  }

  copyTransitDeclaration = (previous: TransitDeclaration) => {
    const next = cloneObject(previous);

    delete next.status;
    delete next.dataFromCustoms;
    delete next.requestedTransportExpiryDate;
    delete next.transportExpiryDate;

    next.lrn = nanoId();
    next["isNewTransitDeclaration"] = true;
    next["isContainerDeclaration"] = !!next.data.containerNumber;

    this.addDeclaration(initializeTransit(next));
  }

  get visibleDeclarations(): TransitDeclaration[] {
    return this.transitDeclarations.filter(s => !s['hidden']);
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
  }

  doLoad(dateTimeRange: DateTimeRange): Observable<(TransitDeclaration)[]> {
    return sendQuery('com.portbase.bezoekschip.common.api.transit.FindTransitDeclarations',
      <FindTransitDeclarations>{
        dateTimeRange: dateTimeRange,
        declarantShortName: AppContext.isAdmin() ? null : AppContext.userProfile.organisation?.shortName,
        term: this.filterTerm,
        dateFilterType: this.transitDateFilterType,
        maxHits: computeMaxHits(),
        untyped: true
      }, {caching: false, showSpinner: true})
      .pipe(map((d: TransitDeclaration[]) => {
        return this.zone.runOutsideAngular(() => this.items.filter(d => !d.status.phase)
          .concat(d.map(declaration => initializeTransit(declaration))));
      }));

    function computeMaxHits(): number {
      const start = moment(dateTimeRange.start);
      const end = moment(dateTimeRange.end);
      const days = end.diff(start, 'days');
      return 1000 * Math.max(days, 1);
    }
  }

  doRender = (declarations: TransitDeclaration[]) => {
    this.zone.runOutsideAngular(() => {
      if (this.transitFilter.selectedOptions.length !== 0) {
        declarations = declarations.filter(t => this.transitFilter.selectedOptions.some(option => option === t.status.phase
          || this.transitFilter.checkCondition(option, t)
        ));
      }
      if (this.transitFilter.deselectedOptions.length !== 0) {
        declarations = declarations.filter(t => !this.transitFilter.deselectedOptions.some(option => option === t.status.phase
          || this.transitFilter.checkCondition(option, t)
        ));
      }
      declarations = declarations.sort(this.getComparator());
    });
    this.transitDeclarations = declarations;
  };

  addNewContainerDeclaration = () => {
    let transitDeclaration: TransitDeclaration = initializeTransit();
    transitDeclaration["isNewTransitDeclaration"] = true;
    transitDeclaration["isContainerDeclaration"] = true;
    this.addDeclaration(transitDeclaration);
  };

  addNewBulkDeclaration = () => {
    let transitDeclaration: TransitDeclaration = initializeTransit();
    transitDeclaration["isNewTransitDeclaration"] = true;
    transitDeclaration["isContainerDeclaration"] = false;
    this.addDeclaration(transitDeclaration);
  };

  addDeclaration = (declaration: TransitDeclaration) => {
    this.transitDeclarations.splice(0, 0, initializeTransit(declaration));
  };

  handleStatusUpdate = (declaration: TransitDeclaration) => {
    declaration = initializeTransit(declaration);
    let existing = this.items.find(i => i.lrn === declaration.lrn);

    copyHouseConsignmentsGoodsItemsToMasterGoodsItems(declaration.data);

    if (!existing) {
      this.items.push(declaration);
    } else {
      lodash.assign(existing, declaration);
    }
  };

  applyTransitFilters(transitFilter: TransitFilter) {
    this.transitFilter = transitFilter;
    this.renderFilteredItems();
  }

  private subscribeToTransitUpdates = () => {
    if (environment.production || environment.docker) {
      try {
        this.socket = new WebSocket(toWebsocketUrl('/api/ui/transit-declarations'));
      } catch (e) {
        console.info('Could not open websocket. Retrying every minute...', e);
        setTimeout(this.subscribeToTransitUpdates, 60_000);
        return;
      }
      this.socket.onmessage = (message: MessageEvent) => {
        if (typeof message.data === 'string') {
          this.handleStatusUpdate(JSON.parse(message.data));
        }
      };
      this.socket.onclose = (event: CloseEvent) => {
        if (!event.wasClean) {
          console.warn('Websocket closed with reason: ' + event.reason + ' (' + event.code + '). Trying to reconnect...');
        }
        setTimeout(() => this.subscribeToTransitUpdates(), 5_000);
      };
    }
  };

  onDateTypeChanged(option): void {
    this.loadAndRender();
  }

  trackByLrn(index: number, obj: TransitDeclaration): any {
    return obj.lrn;
  }

  removeNewDeclaration(item: TransitDeclaration) {
    return () => {
      removeItem(this.items, item);
      this.renderFilteredItems();
    };
  }

  changeSorting(sortBy: string) {
    this.sortBy = sortBy;
    this.renderFilteredItems();
  }

  protected sortingFormatter = (sortingProperty: string): string => {
    switch (sortingProperty) {
      case "sendDate":
        return `Send date (Ascending)`;
      case "sendDateDescending":
        return `Send date (Descending)`;
      case "containerNumber":
        return `Container number (Ascending)`;
      case "containerNumberDescending":
        return `Container number (Descending)`;
    }
  }

  private getComparator(): (a, b) => number {
    switch (this.sortBy) {
      case 'containerNumber':
        return transitItemComparator.compare;
      case 'containerNumberDescending':
        return transitItemComparator.reverseCompare;
      case 'sendDateDescending':
        return transitStatusComparator.reverseCompare;
    }
    return transitStatusComparator.compare;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.transitDeclarations.forEach(c => {
      delete c['selected'];
    });
    try {
      this.socket.onclose = () => {
      };
      this.socket.close();
      this.socket = null;
    } catch (ignored) {
    }
  }

  /*
    Bulk actions
   */

  get selectedItems(): TransitDeclaration[] {
    return this.transitDeclarations.filter(s => !!s['selected']);
  }

  toggleSelectAll = () => {
    if (this.selectedItems.length === this.visibleDeclarations.length) {
      this.visibleDeclarations.forEach(c => c['selected'] = false);
    } else {
      this.visibleDeclarations.forEach(c => c['selected'] = true);
    }
  };

  printSelected = (original: boolean) => {
    this.selectedItems.forEach(item => {
      sendQuery('com.portbase.bezoekschip.common.api.transit.GetTransitAccompanyingDocument',
        {lrn: item.lrn, original: original}, {caching: false, showSpinner: true})
        .subscribe((file: DownloadableFile) => {
          this.documentsDownloader.next(file);
        });
    });
  };

  subscribeToDownloadedFiles = () => {
    this.documentsDownloader
      .pipe(concatMap(file => this.downloadFile(file)))
      .subscribe({
        next(file: DownloadableFile) {
        }
      });
  }

  private downloadFile(file: DownloadableFile) {
    if (!file) {
      return Promise.resolve(null);
    } else {
      const a = document.createElement('a');
      a.href = `data:${(file.mediaType)};base64,${(file.data)}`;
      a.setAttribute('download', file.fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      return new Promise(resolve => setTimeout(() => resolve(file), 100));
    }
  }

  downloadCsv() {
    downloadTransitCsv(this.selectedItems);
  }

  calculateTransportExpiryDate(date: string, transportTerm: string): string {
    return moment(date).add(transportTerm, 'seconds').format("DD-MM-YYYY");
  }

  transportTermToDays(transportTerm: string): number {
    return moment.duration(transportTerm, 'seconds').asDays();
  }

  determineStatusTooltip(status: TransitStatus): string {
    if (status.sent?.date) {
      return "date sent / transport expiry date";
    } else if (status.sendOnAta) {
      return "on ATA / transport term";
    } else {
      return "send date / transport expiry date";
    }
  }
}
