import {Component} from "@angular/core";
import {uuid} from "../../common/utils";
import {PortvisitUtils} from "../../refdata/portvisit-utils";

class FuelExemption {
  id: string;
  requestType?: string;
  requestedDate?: string;
  facilities?: string[];
  status?: string;
}

@Component({
  selector: 'app-fuel-exemptions',
  templateUrl: './fuel-exemptions.html',
  styleUrls: ['./fuel-exemptions.css']
})
export class FuelExemptions {
  fuelExemptions: FuelExemption[] = [];

  addExemption() {
    this.fuelExemptions.push(<FuelExemption>{id: uuid(), status: "new"})
  }

  protected readonly refData = PortvisitUtils;
}
