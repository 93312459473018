<div class="row head">
    <div class="col-2 ms-auto" style="margin-top:12px; margin-bottom:12px">
        <button type="button" class="btn btn-primary" (click)="addExemption();">
            <i class="button-icon fa-solid fa-light fa-plus"></i> <span
                class="ps-2 d-none d-md-inline">New request</span>
        </button>
    </div>
</div>
<hr style="margin: 0.1em"/>
<div class="row">
    <div class="col-5">Facilities</div>
    <div class="col-2">Request type</div>
    <div class="col-2">Requested</div>
    <div class="col-2">Status</div>
    <div class="col-1"></div>
</div>
<hr style="margin: 0.1em"/>

<ng-container *ngFor="let fuelExemption of fuelExemptions;">
    <app-collapse [collapse]="collapz" [showOnLoad]="!fuelExemption.requestedDate">
        <div class="row">
            <div class="col-5">
                <ul class="taglist">
                    <li *ngFor="let facility of fuelExemption.facilities">{{ facility }}</li>
                </ul>
            </div>
            <div class="col-2">{{ fuelExemption.requestType }}</div>
            <div class="col-2">{{ fuelExemption.requestedDate | date:'dd-MM-YYYY' }}</div>
            <div class="col-2">{{ fuelExemption.status }}</div>
            <div class="col-1"></div>
        </div>
    </app-collapse>

    <ng-template #collapz>
        <div class="collapse">
            <div class="py-3 ms-2">
                <div class="row" style="margin-bottom: 12px; margin-right: 4px;">
                    <button type="button" class="btn btn-primary ms-auto col-md-auto" (click)="send(fuelExemption);">
                        <span class="ps-2 d-none d-md-inline">Send</span>
                    </button>
                </div>
                <app-form-group label="Type">
                    <app-select [(ngModel)]="fuelExemption.requestType" required
                                [options]="['Ship to Ship', 'Truck to Ship', 'Mobile Facility to Ship']">
                    </app-select>
                </app-form-group>
                <app-form-group label="Bunker vessels" *ngIf="fuelExemption.requestType == 'Ship to Ship'">
                    <app-multi-input [(ngModel)]="fuelExemption.facilities" required>
                    </app-multi-input>
                </app-form-group>
                <app-form-group label="Trucks" *ngIf="fuelExemption.requestType == 'Truck to Ship'">
                    <app-multi-input [(ngModel)]="fuelExemption.facilities" required>
                    </app-multi-input>
                </app-form-group>
                <app-form-group label="Facilities" *ngIf="fuelExemption.requestType == 'Mobile Facility to Ship'">
                    <app-multi-input [(ngModel)]="fuelExemption.facilities" required>
                    </app-multi-input>
                </app-form-group>
            </div>
        </div>
    </ng-template>
</ng-container>


