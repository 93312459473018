import {
  Carrier,
  Country,
  CustomsOffice,
  DocumentType,
  FindDocumentTypes,
  FindGoodsClassifications,
  FindVisits,
  FindVisitsResult,
  GoodsClassification,
  IE3AddressBook,
  IE3CL116,
  IE3CL214,
  IE3CL704,
  IE3CL710,
  IE3CL711,
  IE3CL745,
  IE3ClearanceGoodsItem,
  IE3Commodity,
  IE3CommodityCode,
  IE3ConsignmentHouseLevel,
  IE3ConsignmentProcess,
  IE3ConsignmentProcessSummary,
  IE3ConsignmentStatus,
  IE3ControlNotification,
  IE3CustomsInspection,
  IE3CustomsProcess,
  IE3CustomsStatus,
  IE3DeclareConsignment,
  IE3DischargeStatus,
  IE3EnsStatus,
  IE3EquipmentSummary,
  IE3FilingStatusTemporaryStorage,
  IE3FilingType,
  IE3FindParties,
  IE3GoodsItem,
  IE3GoodsItemSummary,
  IE3GoodsPlacement,
  IE3GoodsPlacementSummary,
  IE3Party,
  IE3PartyPersonType,
  IE3Place,
  IE3SizeType,
  IE3TemporaryStorageFilingStatus,
  IE3TransportConditionCode,
  IE3TransportEquipment,
  InspectionStatus,
  InspectionType,
  Port,
  TaskMessageStatus,
  VisitSummary,
  VisitSummarySortingProperty
} from "@portbase/bezoekschip-service-typescriptmodels";
import {PortvisitUtils} from "../../refdata/portvisit-utils";
import {Observable, of} from "rxjs";
import {
  closeEditModal,
  formDataSaved,
  nonNull,
  objectValuesNull,
  publishEvent,
  sendCommand,
  sendQuery
} from "../../common/utils";
import {map} from "rxjs/operators";
import lodash, {cloneDeep} from "lodash";
import {AppContext} from "../../app-context";
import {TemplateRef} from "@angular/core";
import {MiddleEllipsisPipe} from "../../common/middle-ellipsis.pipe";
import {
  ConsignmentRouteInfo
} from "./details/master-consignment-details/master-consignment-route/master-consignment-route.component";
import moment from "moment/moment";
import {DeclarationMessageStatus} from "../visit-overview/visit-overview-item/visit-overview.utils";

export class ConsignmentUtils {

  private static euCountries: string[] = ["GF", "NL", "FR", "RE", "SE", "LU", "MT", "DK", "SK", "BE", "EE", "SI", "YT",
    "FI", "CY", "PT", "MC", "HR", "IE", "CZ", "MQ", "ES", "LT", "BG", "LV", "IT", "AX", "PL", "GR", "RO", "HU", "DE", "NO"];

  private static northernIrelandPorts: string[] = ["IEBEF", "GBBEL", "GBBAN", "GBLAR", "GBWPT"];

  private static allCountries: Map<string, Country>;

  static isDutchPort = (place: IE3Place) => place?.countryUnCode === "NL";
  static isShortLanded = (consignmentProcess: IE3ConsignmentProcess, equipmentNumber: string) =>
    consignmentProcess?.shortLandedContainers?.includes(equipmentNumber);
  static isDischarged = (consignmentProcess: IE3ConsignmentProcess, equipmentNumber: string) =>
    consignmentProcess?.dischargedContainers?.includes(equipmentNumber);
  static getDischargedStatus = (dischargeStatus: IE3DischargeStatus): DeclarationMessageStatus => ({
    name: PortvisitUtils.enumFormatter(dischargeStatus),
    messages: [],
    taskStatus: dischargeStatus === IE3DischargeStatus.SHORTLANDED
      ? TaskMessageStatus.ERROR : dischargeStatus === IE3DischargeStatus.DISCHARGED
        ? TaskMessageStatus.ACCEPTED : TaskMessageStatus.DISABLED,
    customLabel: `Equipment is ${PortvisitUtils.enumFormatter(dischargeStatus).toLowerCase()}`
  });
  static isEuPort = (place: IE3Place) => place?.euLocation;
  static isNorthernIrelandPort = (unloCode: string) => this.northernIrelandPorts.includes(unloCode);
  static isEuCountryOrNorthernIreland = (countryCode: string, unloCode: string) =>
    this.isNorthernIrelandPort(unloCode) || this.euCountries.includes(countryCode)

  static ensFilingRequired = (filingType: IE3FilingType) => [IE3FilingType.F10, IE3FilingType.F11, IE3FilingType.F12,
    IE3FilingType.F13, IE3FilingType.F50].includes(filingType);

  static constructConsignmentProcessId = (cargoDeclarantId: string, consignmentNumber: string) =>
    !cargoDeclarantId || !consignmentNumber ? null : `${cargoDeclarantId}_${consignmentNumber}`;
  static formatDate = (dateString: string): string => !!dateString ? moment(dateString).format("DD MMM HH:mm") : null;
  static splitCommodityCode = (code: string): IE3CommodityCode => {
    let codeTrimmed: string = code.trim();
    return {
      harmonizedSystemSubHeadingCode: codeTrimmed.length <= 6 ? codeTrimmed : codeTrimmed.substring(0, 6),
      combinedNomenclatureCode: codeTrimmed.length > 6 ? codeTrimmed.substring(6) : null
    }
  }
  static findCommodities = term => sendQuery("com.portbase.bezoekschip.common.api.cargo.FindGoodsClassifications", <FindGoodsClassifications>{
    term: term,
    minimumCodeSize: 6
  }).pipe(map((g: GoodsClassification[]) => g.map(s => (<IE3Commodity>{
    descriptionOfGoods: s.description,
    commodityCode: ConsignmentUtils.splitCommodityCode(s.code)
  }))));
  static commodityFormatter = (commodity: IE3Commodity) => commodity && commodity.commodityCode ?
    MiddleEllipsisPipe.format(`${commodity.commodityCode ? (commodity.commodityCode?.harmonizedSystemSubHeadingCode || '') + (commodity.commodityCode?.combinedNomenclatureCode || '') : ''} – ${commodity.descriptionOfGoods}`, 50) : '';

  static findDocumentTypes = term => sendQuery("com.portbase.bezoekschip.common.api.cargo.FindDocumentTypes", <FindDocumentTypes>{term: term});
  static documentTypeFormatter = (document: DocumentType) => document ?
    `${document.code} – ${document.name}` : '';

  static goodsItemsAsText(goodsItem: IE3GoodsItemSummary) {
    const commodityText = (goodsItem.commodity?.commodityCode?.harmonizedSystemSubHeadingCode || '') + (goodsItem.commodity?.commodityCode?.combinedNomenclatureCode || '');
    return `${commodityText.length ? commodityText + ' - ' : ''}${goodsItem.commodity?.descriptionOfGoods}`;
  }

  static getCountries = (): Observable<Map<string, Country>> => this.allCountries ? of(this.allCountries)
    : sendQuery("com.portbase.bezoekschip.common.api.visit.GetCountries", {})
      .pipe(map((c: Country[]) => {
        this.allCountries = c.reduce((map, country) => {
          if (country.code) {
            map.set(country.code, country);
          }
          return map;
        }, new Map<string, Country>());
        return this.allCountries;
      }));

  static findVisits = term => sendQuery("com.portbase.bezoekschip.common.api.visit.FindVisits", <FindVisits>{
    maxHits: 100,
    term: term,
    sortingProperty: VisitSummarySortingProperty.CREATED
  }).pipe(map((result: FindVisitsResult) => result.visits));

  static visitFormatter = (visit: VisitSummary | string) => visit ? lodash.isString(visit) ? visit : visit.crn : null;

  static transportConditionCodes: IE3TransportConditionCode[] = [null, IE3TransportConditionCode.DOOR_TO_DOOR,
    IE3TransportConditionCode.DOOR_TO_PIER, IE3TransportConditionCode.PIER_TO_DOOR,
    IE3TransportConditionCode.PIER_TO_PIER];
  static customsProcesses: IE3CustomsProcess[] = [null, IE3CustomsProcess.SEA_IN_SEA_OUT, IE3CustomsProcess.MILITARY,
    IE3CustomsProcess.EMPTY_RETURN_PACKAGING];
  static customsStatuses: IE3CustomsStatus[] = [null, IE3CustomsStatus.EU_COMMUNITY_GOODS,
    IE3CustomsStatus.EU_COMMUNITY_GOODS_IN_TRANSHIPMENT, IE3CustomsStatus.EU_PROCEDURE_T, IE3CustomsStatus.EU_PROCEDURE_T1,
    IE3CustomsStatus.EU_PROCEDURE_T2, IE3CustomsStatus.EU_PROCEDURE_T2F, IE3CustomsStatus.GOODS_FROM_EVA_COUNTRIES];
  static typeOfPartyPersons: IE3PartyPersonType[] = [null, IE3PartyPersonType.NATURAL_PERSON,
    IE3PartyPersonType.LEGAL_PERSON, IE3PartyPersonType.ASSOCIATIONS_OF_PERSONS];
  static methodsOfPayment: IE3CL116[] = [null, IE3CL116.A, IE3CL116.B, IE3CL116.C, IE3CL116.D, IE3CL116.H, IE3CL116.Y,
    IE3CL116.Z];
  static filingTypes: IE3FilingType[] = [IE3FilingType.F10, IE3FilingType.F11, IE3FilingType.F12, IE3FilingType.F13, IE3FilingType.F50];
  static supplementaryFilingTypes: IE3CL745[] = [null, IE3CL745.N1, IE3CL745.N2];
  static documentTypes: IE3CL214[] = [null, IE3CL214.C612, IE3CL214.C620, IE3CL214.N355, IE3CL214.N820, IE3CL214.N821,
    IE3CL214.N822, IE3CL214.N825, IE3CL214.N952, IE3CL214.N955];
  static additionalSupplyChainTypes: IE3CL704[] = [IE3CL704.CS, IE3CL704.FW, IE3CL704.WH];

  static filingTypeFormatter = (value: IE3FilingType, fromVisit: boolean) => {
    switch (value) {
      case IE3FilingType.F10:
        return "Straight B/L single"; // "Single filing straight B/L"
      case IE3FilingType.F11:
        return "Master B/L single"; // "Single filing master B/L + house B/L"
      case IE3FilingType.F12:
        return "Master B/L multiple"; // "Multiple filing master B/L only"
      case IE3FilingType.F13:
        return "Straight B/L multiple"; // "Multiple filing straight B/L only"
      case IE3FilingType.F50:
        return "Road B/L"; // "Road B/L"
      case IE3FilingType.TSR:
        return "Temporary Storage (reuse)"; // "Temporary Storage Reuse"
      case IE3FilingType.TSD:
        return "Temporary Storage"; // "Temporary Storage Declaration"
      case null:
        return fromVisit ? "Pre ICS2 B/L" : "Partial B/L"; // No filing means cargo from old Visit migrated to the new consignments
    }
  }

  static methodOfPaymentFormatter = (value: IE3CL116) => {
    switch (value) {
      case IE3CL116.A:
        return "Payment in cash";
      case IE3CL116.B:
        return "Payment by credit card";
      case IE3CL116.C:
        return "Payment by cheque";
      case IE3CL116.D:
        return "Other (e.g. direct debit to cash account)";
      case IE3CL116.H:
        return "Electronic funds transfer";
      case IE3CL116.Y:
        return "Account holder with carrier";
      case IE3CL116.Z:
        return "Not pre-paid";
    }
  }

  static findSizeTypes = term => sendQuery("com.portbase.bezoekschip.common.api.cargo.FindSizeTypes", {term: term});

  static sizeTypeFormatter = (sizeType: IE3SizeType) => sizeType ? (sizeType.name || '') + (sizeType.code === sizeType.name ? '' : ' – ' + sizeType.code) : ''

  static supportingDocumentTypeFormatter = (type: IE3CL214): string => {
    let name;
    switch (type) {
      case IE3CL214.C612:
        name = "T2F";
        break;
      case IE3CL214.C620:
        name = "T2LF";
        break;
      case IE3CL214.N355:
        name = "ENS";
        break
      case IE3CL214.N820:
        name = "AANG.DOUANEVERVOER,T";
        break;
      case IE3CL214.N821:
        name = "T1";
        break;
      case IE3CL214.N822:
        name = "T2";
        break;
      case IE3CL214.N825:
        name = "T2L";
        break;
      case IE3CL214.N952:
        name = "TIR";
        break;
      case IE3CL214.N955:
        name = "ATA";
        break;
      case null:
      case undefined:
        return "";
    }
    return `${type} – ${name}`;
  }

  static getCustomsStatusCode = (status: IE3CustomsStatus) => {
    switch (status) {
      case 'EU_COMMUNITY_GOODS':
        return 'C';
      case 'EU_COMMUNITY_GOODS_IN_TRANSHIPMENT':
        return 'N27';
      case 'EU_EMPTY_RETURN_PACKAGING':
        return 'NP';
      case 'EU_PROCEDURE_T':
        return 'T';
      case 'EU_PROCEDURE_T1':
        return 'T1';
      case 'EU_PROCEDURE_T2':
        return 'T2';
      case 'EU_PROCEDURE_T2F':
        return 'T2F';
      case 'GOODS_FROM_EVA_COUNTRIES':
        return 'TV';
    }
  }

  static getCustomsProcessCode = (process: IE3CustomsProcess) => {
    switch (process) {
      case 'SEA_IN_SEA_OUT':
        return 'Sea in sea out';
      case 'MILITARY':
        return 'Military';
      case 'EMPTY_RETURN_PACKAGING':
        return 'Empty return packaging';
    }
  }

  static supplierTypes: IE3CL711[] = [null, IE3CL711.N1, IE3CL711.N2];

  static supplierTypeFormatter = (value: IE3CL711) => {
    switch (value) {
      case IE3CL711.N1:
        return "Shipper supplied";
      case IE3CL711.N2:
        return "Carrier supplied";
    }
  }

  static findParties = (term: string, cargoDeclarantId: string, party?: IE3Party): Observable<IE3AddressBook[]> =>
    sendQuery("com.portbase.bezoekschip.common.api.consignments.addressbook.FindParties", <IE3FindParties>{
      term: term,
      cargoDeclarantId: cargoDeclarantId,
      party: party
    }, {caching: false});

  static addressBookFormatter = (addressBook: IE3AddressBook): string => addressBook?.party
    ? this.partyFormatter(addressBook.party) : '';

  static partyFormatter = (party: IE3Party) => `${party.name}${party.address?.city ? ' (' + party.address.city + ')' : ''}`;

  static findCarriers = (term: string): Observable<Carrier[]> => PortvisitUtils.findContainerOperators(term);

  static carrierFormatter = (carrier: Carrier) => `${carrier?.name || ''}`;

  static customProcessNameFormatter = (process: IE3CustomsProcess): string => {
    switch (process) {
      case IE3CustomsProcess.SEA_IN_SEA_OUT:
        return "27 (Sea in sea out)"
      case IE3CustomsProcess.MILITARY:
        return "302 (Military)"
      case IE3CustomsProcess.EMPTY_RETURN_PACKAGING:
        return "P (Empty returning packaging)"
    }
  }

  static customsOfficeFormatter = (customsOffice: CustomsOffice) => customsOffice ? `${customsOffice.name} – ${customsOffice.unCode}` : "";

  static supplyChainTypeFormatter = (value: IE3CL704): string => {
    switch (value) {
      case IE3CL704.CS:
        return "Consolidator";
      case IE3CL704.FW:
        return "Freight forwarder";
      case IE3CL704.WH:
        return "Warehouse keeper";
      default:
        return "N/A";
    }
  }

  static getEquipmentNumbersOfHouseConsignment = (consignmentProcess: IE3ConsignmentProcess, houseLevel: IE3ConsignmentHouseLevel): string[] => {
    return Object.keys(consignmentProcess.consignmentMasterLevel.transportEquipmentMap)
      .filter(containerIdentificationNumber => this.isStraightConsignment(consignmentProcess)
        || lodash.flatMap(houseLevel.goodsItems, g => g.goodsPlacements
          .map(p => p.containerIdentificationNumber)).includes(containerIdentificationNumber));
  }

  static toConsignmentGoodsItemsSummaries = (goodsItems: IE3GoodsItem[]): IE3GoodsItemSummary[] =>
    goodsItems.map(g => ({
      goodsItemNumber: g.goodsItemNumber,
      commodity: g.commodity,
      netWeight: g.netWeight,
      grossWeight: g.grossWeight,
      placements: g.goodsPlacements.map(p => ({
        containerIdentificationNumber: p.containerIdentificationNumber,
        numberOfPackages: p.numberOfPackages,
        grossWeight: p.grossWeight
      }))
    }));

  static placeFormatter = (place: IE3Place) => place ? `${place.name} (${place.locationUnCode})` : '';

  static hasBeenDeclared = (consignmentProcess: IE3ConsignmentProcess): boolean => consignmentProcess.declared;

  static trackByGoodsItemAndEquipment = (index: number, item: EquipmentSummaryItem) => `${item.equipment.equipmentNumber}`;

  static trackByGoodsItemWithHouseConsignments = (index: number, item: GoodsItemSummaryWithHouseConsignments) => `${item.goodsItem.goodsItemNumber}-${item.houseConsignments.join(',')}`

  static goodsItemsWithContainerForSummary = (consignment: IE3ConsignmentProcessSummary, equipment: IE3EquipmentSummary[]): EquipmentSummaryItem[] =>
    lodash.map(equipment, e => ({
      equipment: e,
      goodsItems: this.getGoodsItemInContainer(e, this.getGoodsOfConsignment(consignment).map(g => g.goodsItem), consignment),
      houseConsignments: consignment.masterConsignment.houseConsignments
        .filter(h => h.equipments.some(eq => eq.equipmentNumber === e.equipmentNumber))
        .map(h => h.consignmentNumber)
    }));

  static getGoodsOfConsignmentProcess = (consignment: IE3ConsignmentProcess): GoodsItemWithHouseConsignments[] => this.isStraight(consignment.filing.filingType, consignment.filing.straight)
    ? lodash.flatMap(consignment.consignmentMasterLevel.consignmentsHouseLevel, (h => h.goodsItems.map(g => (<GoodsItemWithHouseConsignments>{
      goodsItem: g,
      houseConsignments: [h],
      clearance: null
    })))) : consignment.consignmentMasterLevel.goodsItems.map(g => (<GoodsItemWithHouseConsignments>{
      goodsItem: g,
      houseConsignments: [],
      clearance: null
    }));

  static getGoodsOfConsignment = (consignment: IE3ConsignmentProcessSummary): GoodsItemSummaryWithHouseConsignments[] => this.isStraight(consignment.filingType, consignment.straight)
    ? lodash.flatMap(consignment.masterConsignment.houseConsignments, (h => h.goodsItems.map(g => ({
      goodsItem: g,
      houseConsignments: [h.consignmentNumber],
      clearance: null
    })))) : consignment.masterConsignment.goodsItems.map(g => ({
      goodsItem: g,
      houseConsignments: [],
      clearance: null
    }));

  static bulkGoodsItemsForSummary = (consignment: IE3ConsignmentProcessSummary): GoodsItemSummaryWithHouseConsignments[] =>
    this.getGoodsOfConsignment(consignment).filter(g => !g.goodsItem.placements.length).map(g => (<GoodsItemSummaryWithHouseConsignments>{
      goodsItem: g.goodsItem,
      houseConsignments: g.houseConsignments,
      clearance: this.getClearanceOfGoodsItem(consignment.status, g.goodsItem.goodsItemNumber)
    }));

  static getClearanceOfGoodsItem = (status: IE3ConsignmentStatus, goodsItemIndex: number) =>
    status?.clearanceStatus?.expirySummary?.items.find(i => i.itemNumber === goodsItemIndex);

  private static getGoodsItemInContainer = (c: IE3EquipmentSummary, goodsItems: IE3GoodsItemSummary[], consignment: IE3ConsignmentProcessSummary): GoodsItemWithEquipment[] => {
    return goodsItems
      .filter(g => g.placements
        .find(p => p.containerIdentificationNumber === c.equipmentNumber))
      .map(g => (<GoodsItemWithEquipment>{
        goodsItem: g,
        placement: g.placements.find(p => p.containerIdentificationNumber === c.equipmentNumber),
        clearance: this.getClearanceOfGoodsItem(consignment.status, g.goodsItemNumber)
      }));
  }

  static getGoodsItem = (consignmentProcess: IE3ConsignmentProcess, goodsItemNumber: number, houseConsignmentNumber?: string): IE3GoodsItem => {
    return (houseConsignmentNumber ? lodash.flatMap(consignmentProcess.consignmentMasterLevel.consignmentsHouseLevel,
        h => h.goodsItems)
      : consignmentProcess.consignmentMasterLevel.goodsItems).find(g => g.goodsItemNumber === goodsItemNumber);
  }

  static getGoodsOfEquipment = (consignmentProcess: IE3ConsignmentProcess, equipmentNumber: string, houseConsignmentNumber?: string): IE3GoodsItem[] => {
    return houseConsignmentNumber
      ? consignmentProcess.consignmentMasterLevel.consignmentsHouseLevel
        .find(h => h.consignmentNumber === houseConsignmentNumber)
        .goodsItems.filter(g => g.goodsPlacements.some(
          p => p.containerIdentificationNumber === equipmentNumber))
      : consignmentProcess.consignmentMasterLevel.goodsItems.filter(g => g.goodsPlacements
        .some(p => p.containerIdentificationNumber === equipmentNumber))
        .concat(lodash.flatMap(consignmentProcess.consignmentMasterLevel.consignmentsHouseLevel,
          h => h.goodsItems.filter(g => g.goodsPlacements
            .some(p => p.containerIdentificationNumber === equipmentNumber))));
  }

  static getContainerWithPlacement = (c: IE3TransportEquipment, goodsItem: IE3GoodsItem): EquipmentWithPlacement => {
    return {
      equipment: c,
      placement: goodsItem.goodsPlacements.find(g => g.containerIdentificationNumber === c.containerIdentificationNumber)
    }
  }

  static allGoodsItems = (c: IE3ConsignmentProcess): IE3GoodsItem[] => c.consignmentMasterLevel.goodsItems
    .concat(lodash.flatMap(c.consignmentMasterLevel.consignmentsHouseLevel, h => h.goodsItems));

  static getEquipmentIconCls = (sizeType: IE3SizeType, empty: boolean): string => {
    if (empty) {
      return "fa-kit fa-pb-container-empty";
    }
    switch (sizeType?.code as IE3CL710) {
      case IE3CL710.N1:
      case IE3CL710.N2:
      case IE3CL710.N6:
      case IE3CL710.N7:
      case IE3CL710.N9:
      case IE3CL710.N18:
      case IE3CL710.N19:
      case IE3CL710.N20:
      case IE3CL710.N27:
      case IE3CL710.N28:
      case IE3CL710.N29:
        return "fa-kit fa-pb-container-tank";
      case IE3CL710.N15:
      case IE3CL710.N24:
      case IE3CL710.N25:
      case IE3CL710.N26:
      case IE3CL710.N30:
      case IE3CL710.N31:
      case IE3CL710.N32:
      case IE3CL710.N39:
      case IE3CL710.N40:
      case IE3CL710.N41:
        return "fa-kit fa-pb-container-refrigerated";
      case IE3CL710.N43:
        return "fa-kit fa-pb-container-open-top";
      case IE3CL710.N12:
      case IE3CL710.N13:
      case IE3CL710.N14:
      case IE3CL710.N16:
      case IE3CL710.N17:
      case IE3CL710.N21:
      case IE3CL710.N22:
      case IE3CL710.N23:
      case IE3CL710.N33:
      case IE3CL710.N34:
      case IE3CL710.N35:
      case IE3CL710.N36:
      case IE3CL710.N37:
      case IE3CL710.N38:
      case IE3CL710.N42:
      case IE3CL710.N44:
      case IE3CL710.N45:
        return "fa-container-storage";
      default:
        return "fa-container-storage";
    }
  }

  static allowedToEdit = (): boolean => AppContext.isAdmin() || AppContext.isCargoImportEditor();

  static isEditable = (consignment: IE3ConsignmentProcess): boolean => this.allowedToEdit() && !consignment.fromVisit;

  static isSummaryAllowedToDeclare = (consignment: IE3ConsignmentProcessSummary): boolean => !consignment.fromVisit;

  static isAllowedToDeclare = (consignment: IE3ConsignmentProcess): boolean => !consignment.fromVisit;

  static isMasterConsignment = (consignment: IE3ConsignmentProcess): boolean => [IE3FilingType.F11, IE3FilingType.F12]
    .includes(consignment.filing.filingType);

  static isStraightConsignment = (consignment: IE3ConsignmentProcess): boolean => consignment.filing.straight ||
    [IE3FilingType.F10, IE3FilingType.F13].includes(consignment.filing.filingType);

  static isStraight = (filingType: IE3FilingType, straight: boolean): boolean => straight ||
    [IE3FilingType.F10, IE3FilingType.F13].includes(filingType);

  static isRoadConsignment = (consignment: IE3ConsignmentProcess): boolean => [IE3FilingType.F50]
    .includes(consignment.filing.filingType);

  static isMultipleFilingConsignment = (consignment: IE3ConsignmentProcess): boolean => [IE3FilingType.F12, IE3FilingType.F13]
    .includes(consignment.filing.filingType);

  static isSingleFilingConsignment = (consignment: IE3ConsignmentProcess): boolean => [IE3FilingType.F10, IE3FilingType.F11, IE3FilingType.TSD]
    .includes(consignment.filing.filingType);

  static isSingleFilingConsignmentOrRoad = (consignment: IE3ConsignmentProcess): boolean => [IE3FilingType.F10, IE3FilingType.F11, IE3FilingType.F50]
    .includes(consignment.filing.filingType);

  static isTemporaryStorageOnlyConsignment = (filingType: IE3FilingType): boolean => [IE3FilingType.TSD, IE3FilingType.TSR]
    .includes(filingType);

  static houseConsignmentSupported = (consignment: IE3ConsignmentProcess): boolean =>
    this.isStraightConsignment(consignment) || this.isSingleFilingConsignment(consignment) || consignment.filing.filingType === IE3FilingType.F50;

  static hasHouseLevelConsignments = (consignment: IE3ConsignmentProcess): boolean =>
    [IE3FilingType.F10, IE3FilingType.F11, IE3FilingType.F13].includes(consignment.filing.filingType);

  static openSubModal = (payload: ConsignmentSubModalEvent) => publishEvent("openConsignmentSubModal", payload);

  static closeSubModal = () => publishEvent("closeConsignmentSubModal");

  static getMessagesEns = (taskStatus: TaskMessageStatus, status: IE3ConsignmentStatus): string[] => {
    if (!status) {
      return [];
    }
    const examinationPlace = (controlNotification: IE3ControlNotification) =>
      controlNotification.controls.map(control => control.examinationPlace)
        .map(place => place.placeOfExamination || place.referenceNumber).join(", ");

    switch (taskStatus) {
      case TaskMessageStatus.ERROR:
        switch (status.latestEnsStatus) {
          case IE3EnsStatus.DO_NOT_LOAD:
            return status.doNotLoadRequest.doNotLoadDetails.transportEquipment.length
              ? [`Do not load equipment: ${status.doNotLoadRequest.doNotLoadDetails.transportEquipment.map(t => t.containerIdentificationNumber).join(", ")}`]
              : ["Do not load this consignment"];
          default:
            return [];
        }
      case TaskMessageStatus.REJECTED:
        switch (status.latestEnsStatus) {
          case IE3EnsStatus.HOUSE_CONSIGNMENT_INCORRECT_STATE_NOTIFICATION:
            return status.houseConsignmentInIncorrectStateNotification?.errors?.map(e => e.description || e.technicalErrorMessage);
          case IE3EnsStatus.ERROR_NOTIFICATION:
            return status.filingStatusENS.errornotification.errors.map(e =>
              e.description
                ? e.pointer != null ? e.description + ' at ' + e.pointer : e.description
                : e.technicalErrorMessage);
          case IE3EnsStatus.LIFECYCLE_VALIDATION_ERROR:
            return status.filingStatusENS.lifecycleValidationErrorNotification.errors.map(e => `Code: ${e.validationCode} – ${e.description}`);
          default:
            return [];
        }
      case TaskMessageStatus.WARNING:
        switch (status.latestEnsStatus) {
          case IE3EnsStatus.INFORMATION_REQUESTED:
            return [`Information requested at: ${this.formatDate(status.additionalInformationRequest.documentIssueTime)}`];
          case IE3EnsStatus.INFORMATION_REQUEST_NOTIFIED:
            return [`Information request notified at: ${this.formatDate(status.additionalInformationRequestNotification.documentIssueTime)}`];
          case IE3EnsStatus.CONTROL_NOTIFICATION:
            return [`<span>Control scheduled at: ${this.formatDate(status.controlNotification.scheduledControlTime)}</span><br/><i class="fa-light fa-fw fa-comment me-1 invisible"></i><span>Examination place: ${(examinationPlace(status.controlNotification))}</span>`];
          case IE3EnsStatus.ADVANCE_CONTROL_NOTIFICATION:
            return [`<span>Control scheduled at: ${this.formatDate(status.advanceControlNotification.scheduledControlTime)}</span><br/><i class="fa-light fa-fw fa-comment me-1 invisible"></i><span>Examination place: ${(examinationPlace(status.advanceControlNotification))}</span>`];
          default:
            return [];
        }
      default:
        return [];
    }
  }

  static getTemporaryStorageStatusMessage = (tsStatus: IE3FilingStatusTemporaryStorage, temporaryStorageStatus?: IE3TemporaryStorageFilingStatus): string[] => {
    const resolveConsignmentNumber = (tag: string) => tag.indexOf('B/L number') > 0 ? ' : [' + tag + ']' : '';
    return (temporaryStorageStatus || tsStatus?.status) === IE3TemporaryStorageFilingStatus.REJECTED
      ? tsStatus?.registrationResponse?.errors?.map(
        e => `${e.description}${e.code ? ' (' + e.code + ')' : ''}${e.tag ? resolveConsignmentNumber(e.tag) : ''}`)
      : [];
  }

  static createRoutesInfo = (consignmentProcess: IE3ConsignmentProcess, countries: Map<string, Country>, itinerary: string[], placeOfAcceptance: IE3Place, placeOfDelivery: IE3Place): ConsignmentRouteInfo[] => {
    const activeBorderTransportMeans = consignmentProcess.consignmentMasterLevel.activeBorderTransportMeans;
    const placeOfLoading = consignmentProcess.consignmentMasterLevel.placeOfLoading;
    const placeOfUnloading = consignmentProcess.consignmentMasterLevel.placeOfUnloading;
    const customsOfficeOfFirstEntry = consignmentProcess.filing.customsOfficeOfFirstEntry;
    const routes: ConsignmentRouteInfo[] = itinerary?.map(c => ({
      country: countries.get(c)
    })) || [];
    let loadingRoute: ConsignmentRouteInfo, acceptanceRoute: ConsignmentRouteInfo, discharge: ConsignmentRouteInfo,
      firstEntryOffice: ConsignmentRouteInfo;
    if (placeOfLoading) {
      loadingRoute = routes.find(v => v.country.code === this.getCountryCode(placeOfLoading));
      if (loadingRoute) {
        loadingRoute.placeOfLoading = placeOfLoading;
        loadingRoute.etd = activeBorderTransportMeans.actualDateAndTimeOfDeparture || activeBorderTransportMeans.estimatedDateAndTimeOfDeparture;
      }
    }
    if (placeOfAcceptance) {
      acceptanceRoute = routes.find(v => v.country.code === this.getCountryCode(placeOfAcceptance));
      if (acceptanceRoute) {
        acceptanceRoute.placeOfAcceptance = placeOfAcceptance;
      }
    }
    if (customsOfficeOfFirstEntry) {
      firstEntryOffice = lodash.find(routes, v => v.country.code === customsOfficeOfFirstEntry.unCode.substring(0, 2),
        loadingRoute ? routes.indexOf(loadingRoute) : acceptanceRoute ? routes.indexOf(acceptanceRoute) : 0);
      if (firstEntryOffice) {
        firstEntryOffice.customsOfficeOfFirstEntry = customsOfficeOfFirstEntry;
      }
    }
    if (placeOfUnloading) {
      discharge = lodash.findLast(routes, v => v.country.code === this.getCountryCode(placeOfUnloading));
      if (discharge) {
        discharge.placeOfUnloading = placeOfUnloading;
        discharge.eta = activeBorderTransportMeans.estimatedDateAndTimeOfArrival;
        discharge.dischargeTerminal = consignmentProcess.consignmentMasterLevel.dischargeTerminal;
        discharge.terminalLicense = consignmentProcess.consignmentMasterLevel.terminalLicense;
      }
    }
    if (placeOfDelivery) {
      const delivery = lodash.find(routes, v => v.country.code === this.getCountryCode(placeOfDelivery),
        discharge ? routes.indexOf(discharge) : firstEntryOffice ? routes.indexOf(firstEntryOffice) : 0);
      if (delivery) {
        delivery.placeOfDelivery = placeOfDelivery;
      }
    }
    return routes;
  }

  static getCountryCode = (place: IE3Place | Port) => {
    return ConsignmentUtils.isNorthernIrelandPort(place.locationUnCode) ? "IE" : place.countryUnCode;
  }

  static getInspectionsStatus = (inspections: IE3CustomsInspection[]): DeclarationMessageStatus => {
    const filteredInspections = inspections?.filter(nonNull);
    if (!filteredInspections.length) {
      return {
        name: "Inspection",
        messages: [],
        taskStatus: TaskMessageStatus.DISABLED
      };
    }
    const hasInspectionWithStatus = (status: InspectionStatus) => filteredInspections.some(l => l.status === status);
    const hasBlock = filteredInspections.some(l => l.type === InspectionType.block && l.status !== InspectionStatus.deleted);
    const hasDeleted = hasInspectionWithStatus(InspectionStatus.deleted);
    const hasExternalScan = hasInspectionWithStatus(InspectionStatus.external_scan);
    const hasPhysicalInspections = filteredInspections.some(l => l.type === InspectionType.physical && !!l.physicalInspectionLocation);
    return {
      name: "Inspection",
      taskStatus: hasBlock ? TaskMessageStatus.ERROR : hasExternalScan || hasPhysicalInspections ? TaskMessageStatus.WARNING
        : filteredInspections?.length || hasDeleted ? TaskMessageStatus.ACCEPTED : TaskMessageStatus.DISABLED,
      messages: [],
      customLabel: hasBlock ? "Blocked" : hasPhysicalInspections ? "Physical inspection"
        : hasExternalScan ? "Inspection through scan" : hasDeleted ? "Inspection deleted" : filteredInspections?.length ? "Released" : "",
    };
  }

  static validateMasterConsignment = (consignmentProcess: IE3ConsignmentProcess, registerErrors: boolean): boolean => {
    const masterLevel = consignmentProcess.consignmentMasterLevel;
    if (this.isStraightConsignment(consignmentProcess) || this.isRoadConsignment(consignmentProcess)) {
      masterLevel.goodsItems = [];
      if (this.isStraightConsignment(consignmentProcess)) {
        masterLevel.placeOfDelivery = null;
        masterLevel.placeOfAcceptance = null;
        masterLevel.consignee = null;
        masterLevel.consignor = null;
      }
    }
    if (masterLevel.carrier) {
      masterLevel.carrier.communications = masterLevel.carrier.communications?.filter(c => c.identifier);
    }
    if (masterLevel.consignee) {
      masterLevel.consignee.communications = masterLevel.consignee.communications?.filter(c => c.identifier);
    }
    if (masterLevel.consignor) {
      masterLevel.consignor.communications = masterLevel.consignor.communications?.filter(c => c.identifier);
    }
    if (masterLevel.goodsItems) {
      masterLevel.goodsItems.forEach((g, i) => g.goodsItemNumber = i + 1);
    }
    if (this.isTemporaryStorageOnlyConsignment(consignmentProcess.filing.filingType)) {
      consignmentProcess.filing.customsOfficeOfFirstEntry = null;
    }
    const itinerary = masterLevel.activeBorderTransportMeans?.itinerary;
    const unloadingUnCode = masterLevel.placeOfUnloading?.countryUnCode;
    if (registerErrors && itinerary?.length && unloadingUnCode) {
      if (itinerary[itinerary.length - 1] !== unloadingUnCode) {
        AppContext.registerError(`Last country must be the same as the place of unloading ${unloadingUnCode}`);
        return false;
      }
    }
    return true;
  }

  static validateHouseConsignment = (consignmentProcess: IE3ConsignmentProcess, houseConsignment: IE3ConsignmentHouseLevel, registerErrors: boolean): boolean => {
    if (!this.validateMasterConsignment(consignmentProcess, registerErrors)) {
      return false;
    }
    if (lodash.isEmpty(houseConsignment.goodsShipment?.buyer) && lodash.isEmpty(houseConsignment.goodsShipment?.seller)) {
      houseConsignment.goodsShipment = null;
    }
    if (!houseConsignment.transportCharges?.methodOfPayment) {
      houseConsignment.transportCharges = null;
    }
    if (houseConsignment.goodsShipment) {
      if (houseConsignment.goodsShipment.buyer) {
        houseConsignment.goodsShipment.buyer.communications = houseConsignment.goodsShipment.buyer.communications?.filter(c => c.identifier);
      }
      if (houseConsignment.goodsShipment.seller) {
        houseConsignment.goodsShipment.seller.communications = houseConsignment.goodsShipment.seller.communications?.filter(c => c.identifier);
      }
    }
    if (houseConsignment.consignee) {
      houseConsignment.consignee.communications = houseConsignment.consignee.communications?.filter(c => c.identifier);
    }
    if (houseConsignment.consignor) {
      houseConsignment.consignor.communications = houseConsignment.consignor.communications?.filter(c => c.identifier);
    }
    if (houseConsignment.goodsItems) {
      houseConsignment.goodsItems.forEach((g, i) => g.goodsItemNumber = i + 1);
    }
    return true;
  }

  static validateGoodsItem = (consignmentProcess: IE3ConsignmentProcess, goodsItem: IE3GoodsItem,
                              registerErrors: boolean, houseConsignment?: IE3ConsignmentHouseLevel): boolean => {
    if (houseConsignment) {
      if (!this.validateHouseConsignment(consignmentProcess, houseConsignment, registerErrors)) {
        return false;
      }
    } else if (!this.validateMasterConsignment(consignmentProcess, registerErrors)) {
      return false;
    }
    if (goodsItem.outerPackaging?.typeOfPackages?.bulk) {
      goodsItem.outerPackaging.numberOfPackages = null;
      goodsItem.innerPackagingList = null;
    }
    if (objectValuesNull(goodsItem.previousDocument)) {
      goodsItem.previousDocument = null;
    }
    if (houseConsignment) {
      const houseConsignments = consignmentProcess.consignmentMasterLevel.consignmentsHouseLevel;
      const foundHouseConsignment = houseConsignments.find(h => h.consignmentNumber === houseConsignment.consignmentNumber);
      if (foundHouseConsignment) {
        const indexOfHouseConsignment = consignmentProcess.consignmentMasterLevel.consignmentsHouseLevel.indexOf(foundHouseConsignment);
        consignmentProcess.consignmentMasterLevel.consignmentsHouseLevel[indexOfHouseConsignment] = houseConsignment;
      } else {
        consignmentProcess.consignmentMasterLevel.consignmentsHouseLevel.push(houseConsignment);
      }
    }
    return true;
  }

  static validateEquipment = (consignmentProcess: IE3ConsignmentProcess, equipment: IE3TransportEquipment,
                              registerErrors: boolean, isNewEquipment: boolean, placement: IE3GoodsPlacement,
                              houseConsignment?: IE3ConsignmentHouseLevel, goodsItem?: IE3GoodsItem): boolean => {
    if (houseConsignment) {
      if (!this.validateHouseConsignment(consignmentProcess, houseConsignment, registerErrors)) {
        return false;
      }
    } else if (!this.validateMasterConsignment(consignmentProcess, registerErrors)) {
      return false;
    }
    consignmentProcess.consignmentMasterLevel.transportEquipmentMap[equipment.containerIdentificationNumber] = equipment;
    if (isNewEquipment && goodsItem) {
      const foundItem = ConsignmentUtils.getGoodsItem(consignmentProcess, goodsItem.goodsItemNumber, houseConsignment?.consignmentNumber);
      if (foundItem && !foundItem.goodsPlacements.find(p => p.containerIdentificationNumber === equipment.containerIdentificationNumber)) {
        foundItem.goodsPlacements.push({
          containerIdentificationNumber: equipment.containerIdentificationNumber,
          ...placement
        });
      }
    }
    return true;
  }

  static declareConsignment = (consignmentProcess: IE3ConsignmentProcess) => {
    const consignment = cloneDeep(consignmentProcess.consignmentMasterLevel);
    if (!consignment.exchangeRate?.currency) {
      consignment.exchangeRate = null;
    }
    if (!consignment.transportCharges?.methodOfPayment) {
      consignment.transportCharges = null;
    }
    sendCommand("com.portbase.bezoekschip.common.api.consignments.commands.DeclareConsignment", <IE3DeclareConsignment>{
      consignment: consignment,
      filing: consignmentProcess.filing
    }, () => {
      AppContext.registerSuccess("Consignment declared successfully");
      formDataSaved();
      closeEditModal();
    });
  }
}

export interface EquipmentSummaryItem {
  goodsItems: GoodsItemWithEquipment[];
  equipment: IE3EquipmentSummary;
  houseConsignments: string[];
}

export interface GoodsItemWithEquipment {
  goodsItem: IE3GoodsItemSummary;
  placement: IE3GoodsPlacementSummary;
  clearance: IE3ClearanceGoodsItem;
}

export interface GoodsItemWithHouseConsignments {
  goodsItem: IE3GoodsItem;
  houseConsignments?: IE3ConsignmentHouseLevel[];
}

export interface GoodsItemSummaryWithHouseConsignments {
  goodsItem: IE3GoodsItemSummary;
  houseConsignments?: string[];
  clearance: IE3ClearanceGoodsItem;
}

export interface EquipmentWithPlacement {
  equipment: IE3TransportEquipment;
  placement?: IE3GoodsPlacement;
  goodsItems?: IE3GoodsItemSummary[];
  houseConsignments?: string[];
}

export interface ConsignmentSubModalEvent {
  modalContent: TemplateRef<any>;
}
