<div class="position-fixed sticky-top w-100" style="left: 0; right: 0; z-index: 15000;">
  <app-alerting></app-alerting>
</div>
<div class="mx-auto mb-5 mt-4">
  <ng-container *ngIf="showExemptions()">
    <app-tab-panel>
      <app-tab-item [templateRef]="declarationsRef">
        <span class="tab-item">Declarations</span>
        <ng-template #declarationsRef>
          <app-bunkering-declaration></app-bunkering-declaration>
        </ng-template>
      </app-tab-item>
      <app-tab-item [templateRef]="fuelExemptionsRef">
        <span class="tab-item">Fuel exemptions</span>
        <ng-template #fuelExemptionsRef>
          <div class="tab-content">
            <app-fuel-exemptions></app-fuel-exemptions>
          </div>
        </ng-template>
      </app-tab-item>
    </app-tab-panel>
  </ng-container>

  <ng-container *ngIf="!showExemptions()">
    <app-bunkering-declaration></app-bunkering-declaration>
  </ng-container>

</div>
